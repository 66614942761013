
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        






























































































































































::v-deep {
  .table {
    td:first-child,
    th:first-child {
      min-width: 300px;
      width: 300px;
      max-width: 300px;
    }

    .detail {
      & > td, .detail-container {
        padding: 0 !important;
      }
      td {
        font-size: 0.75rem;
      }
    }
  }

  .pr-2 {
    .pr-5 {
      padding-right: 0 !important;
    }
  }

  .table-wrapper {
    margin: 0 !important;
  }

  .is-nowrap {
    white-space: nowrap
  }

  .dropdown + .dropdown {
    margin-left: 1em;
  }
}
