
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        














































































































































.commission-dropdown {
  width: fit-content;

  ::v-deep {
    .b-numberinput input[type=number] {
      text-align: left;
    }

    input {
      font-size: 0.85rem;
      width: 60px;
      padding: 0;
      height: 20px;
      border-radius: 0;
      border-color: transparent;
    }

    .input-wrapper {
      display: flex;
      align-items: center;

      background: white;
      border-bottom: 1px solid #dbdbdb;

      &.disabled {
        background-color: hsl(0, 0%, 96%);
        color: hsl(0, 0%, 48%);
        cursor: not-allowed;
      }
    }
  }

  &:not(:last-child)::after {
    content: "";
    position: absolute;
    top: 0;
    right: -0.5em;

    width: 1px;
    height: 95%;
    background: $border-light;
  }
}
