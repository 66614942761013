
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        









































































































































































































.card {
  padding: 12px 16px;
}

.invisible {
  ::v-deep {
    .dropdown-content {
      box-shadow: none;
    }
  }
}

.dropdown {
  width: 100%;
}

::v-deep {
  .dropdown-trigger {
    width: 100%;
  }
  
  .dropdown-menu {
    max-width: 400px;
    min-width: 200px !important;
    text-align: left !important;
    font-size: $size-normal;
    word-wrap: break-word;
    z-index: 9999;
  }

  .dropdown-content {
    padding: 0 !important;
  }
}

