
          @charset "utf-8";
          @import "~bulma/sass/utilities/initial-variables.sass";
          @import "~bulma/sass/utilities/functions.sass";
          @import "~bulma/sass/utilities/derived-variables.sass";
          @import "~bulma/sass/utilities/mixins.sass";
          
          @import "~@/scss/_theme-default.scss";
          @import "~@/scss/_variables.scss";
          @import "~@/scss/_mixins.scss";
        
























































































































































































































































































































































































::v-deep {
  .min-width-145 {
    min-width: 145px !important;
  }

  table {
    th:first-child {
      padding-left: 1.5rem;
    }

    .table-footer {
      display: contents;

      th {
        font-size: 0.85rem;

        &:first-child {
          padding-left: 0 !important;
        }
      }
    }

    tfoot {
      position: static !important;
    }
  }

  .is-nowrap {
    white-space: nowrap
  }

  .th-wrap.is-relative > span {
    width: 100%;
  }
}

  $groups: (
          group-0: .5rem,
          group-1: 1rem,
          group-2: 2rem,
          group-3: 3rem,
          group-4: 4rem,
          group-5: 5rem
  );

  @each $group, $size in $groups {
    .#{ $group } {
      padding-left: $size;

      &.breadcrumbs {
        margin-left: -1.5rem;
      }

      &.is-last {
        margin-left: .5rem;
      }
    }
  }
